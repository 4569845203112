<template lang="pug">
    validation-observer(ref="observer", v-slot="{ passes }")
      b-form(@submit.stop.prevent="passes(onSubmit)")
        b-row
          b-col(cols="12")
            hr
            h5.pb-4 Datos del Vehículo
          b-col(cols="12" md="6")
            validation-provider(name="Marca" rules="required" v-slot="validationContext")
              b-form-group(label="Marca")
                b-form-select(
                  placeholder="Seleccionar",
                  v-model.lazy="vehicle.brand",
                  :options="brands",
                  value-field="id",
                  text-field="name",
                  :state="getValidationState(validationContext)",
                  aria-describedby="input-brand"
                )
                b-form-invalid-feedback#input-brand {{ validationContext.errors[0] }}
          b-col(cols="12", md="6")
            validation-provider(name="Modelo" rules="required" v-slot="validationContext")
              b-form-group(label="Modelo")
                b-form-select(
                  placeholder="Seleccionar",
                  v-model="vehicle.model",
                  :options="models",
                  value-field="id",
                  text-field="name",
                  :disabled="vehicle.brand > 0 ? false : true",
                  :state="getValidationState(validationContext)",
                  aria-describedby="input-model"
                )
                b-form-invalid-feedback#input-model {{ validationContext.errors[0] }}
          b-col(cols="12", md="4")
            validation-provider(name="Versión" rules="required|min:3" v-slot="validationContext")
              b-form-group(label="Versión")
                b-form-input(
                  v-model="vehicle.version",
                  :disabled="vehicle.model > 0 ? false : true",
                  :state="getValidationState(validationContext)",
                  type="text",
                  aria-describedby="input-version"
                )
                b-form-invalid-feedback#input-version {{ validationContext.errors[0] }}
          b-col(cols="12", md="3")
            validation-provider(name="Año" rules="required|numeric|min_value:1960|max_value:2021" v-slot="validationContext")
              b-form-group(label="Año")
                b-form-input(
                  v-model="vehicle.year",
                  type="number",
                  min="1960",
                  max="2021",
                  step="1",
                  :disabled="vehicle.version ? false : true",
                  :state="getValidationState(validationContext)",
                  aria-describedby="input-year"
                )
                b-form-invalid-feedback#input-year {{ validationContext.errors[0] }}
          b-col(cols="12", md="2")
            validation-provider(name="Cilindrada" rules="required" v-slot="validationContext")
              b-form-group(label="Cilindrada")
                b-form-input(
                  v-model="vehicle.displacement",
                  type="number",
                  min="0.1",
                  step="0.1",
                  :disabled="vehicle.year ? false : true",
                  :state="getValidationState(validationContext)",
                  aria-describedby="input-displacement"
                )
                b-form-invalid-feedback#input-displacement {{ validationContext.errors[0] }}
          b-col(cols="12", md="3")
            validation-provider(name="Kilómetraje" rules="required|numeric" v-slot="validationContext")
              b-form-group(label="Kilómetraje")
                b-form-input(
                  v-model="vehicle.mileage",
                  type="number",
                  min="0",
                  step="0.1",
                  :disabled="vehicle.displacement ? false : true",
                  :state="getValidationState(validationContext)",
                  aria-describedby="input-mileage"
                )
                b-form-invalid-feedback#input-mileage {{ validationContext.errors[0] }}
          b-col(cols="12" md="4")
            validation-provider(name="Transmisión" rules="required" v-slot="validationContext")
              b-form-group(label="Transmisión")
                b-form-select(
                  placeholder="Seleccionar",
                  v-model="vehicle.transmission",
                  :options="transmissions",
                  value-field="name",
                  text-field="name",
                  :disabled="vehicle.displacement ? false : true",
                  :state="getValidationState(validationContext)",
                  aria-describedby="input-transmission"
                )
                b-form-invalid-feedback#input-transmission {{ validationContext.errors[0] }}
          b-col(cols="12", md="2")
            validation-provider(name="Nº Dueños" rules="required|numeric" v-slot="validationContext")
              b-form-group(label="Nº Dueños")
                b-form-input(
                  v-model="vehicle.owners"
                  type="number",
                  min="1",
                  step="1",
                  :disabled="vehicle.transmission ? false : true",
                  :state="getValidationState(validationContext)",
                  aria-describedby="input-owners"
                )
                b-form-invalid-feedback#input-doors {{ validationContext.errors[0] }}
          b-col(cols="12")
            hr
            h5.pb-4 Datos del cliente
          b-col(cols="12" md="3")
            validation-provider(name="Nombre" rules="required|min:3" v-slot="validationContext")
              b-form-group(label="Nombre")
                b-form-input(
                  v-model="client.name",
                  :disabled="vehicle.owners > 0 ? false : true",
                  :state="getValidationState(validationContext)",
                  type="text",
                  aria-describedby="input-version"
                )
                b-form-invalid-feedback#input-version {{ validationContext.errors[0] }}
          b-col(cols="12" md="3")
            validation-provider(name="Apellido" rules="required|min:3" v-slot="validationContext")
              b-form-group(label="Apellido")
                b-form-input(
                  v-model="client.lastname",
                  :disabled="client.name ? false : true",
                  :state="getValidationState(validationContext)",
                  type="text",
                  aria-describedby="input-version"
                )
                b-form-invalid-feedback#input-version {{ validationContext.errors[0] }}
          b-col(cols="12" md="3")
            validation-provider(name="Teléfono" rules="required|min:3" v-slot="validationContext")
              b-form-group(label="Teléfono")
                b-form-input(
                  v-model="client.phone",
                  :disabled="client.lastname ? false : true",
                  :state="getValidationState(validationContext)",
                  type="text",
                  aria-describedby="input-version"
                )
                b-form-invalid-feedback#input-version {{ validationContext.errors[0] }}
          b-col(cols="12" md="3")
            validation-provider(name="Correo" rules="required|email|min:3" v-slot="validationContext")
              b-form-group(label="Correo")
                b-form-input(
                  v-model="client.email",
                  :disabled="client.phone ? false : true",
                  :state="getValidationState(validationContext)",
                  type="email",
                  aria-describedby="input-version"
                )
                b-form-invalid-feedback#input-version {{ validationContext.errors[0] }}
          b-col(cols="12")
            b-button(type="submit" :disabled="butonDisabled" block variant="outline-okcarpremium") {{ buttonText }}
</template>
<script>
import { mapState, mapActions } from "vuex"
import Multiselect from 'vue-multiselect'

export default {
  name: 'FormSell',
  components: {
			Multiselect
  },
  data() {
			return {
        vehicle:{
          brand:0,
          model:0,
          version:'',
          year:0,
          displacement: 0,
          mileage:0,
          transmission:'',
          owners:0
        },
        client: {
          name:'',
          lastname:'',
          phone:'',
          email:''
        },
        transmissions: [
          { name: "Seleccionar...", value: null, icon:"" },
					{ name: "Automático", value: "automático", icon: "icon-automatic" },
					{ name: "Manual", value: "manual", icon: "icon-mechanic" }
				],
        butonDisabled: false,
				buttonText: "Evaluar Vehículo",
      }
  },
  computed: {
    ...mapState('brand',['brands']),
    ...mapState('model',['models']),
  },
  methods: {
    ...mapActions('model',['getModels']),
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onSubmit(){
      if (this.$invalid) {
        this.$bvToast.toast("Campos requeridos o Inválidos en el formulario.", {
          title: "Campos requeridos",
          variant: "danger",
          toaster:"b-toaster-bottom-right",
          solid: true
        });
      } else {
          this.butonDisabled = true
          this.buttonText = "Cargando..."
          let dataform = Object.assign(this.vehicle,this.client)
          this.$axios
            .post("/api/v1/sell", dataform)
            .then(res => {
              console.log(res)
              this.$swal({
                title: "Solicitud de vehículo realizado con éxito.",
                text: "Vehículo registrado",
                icon: "success",
                confirmButtonClass: "btn btn-outline-primary",
                confirmButtonText: "Ok",
                buttonsStyling: false
              });
              this.buttonText = "Publicar Vehículo";
              this.vehicle = {
                brand:0,
                model:0,
                version:'',
                year:0,
                displacement: 0,
                mileage:0,
                transmission:'',
                owners:0
              }
              this.client = {
                name:'',
                lastname:'',
                phone:'',
                email:''
              }
              dataform = null
            })
            .catch(error => {
              this.butonDisabled = false;
              this.buttonText = "Evaluar Vehículo";
              this.$swal({
                title: "Registro de vehículo!",
                text: "Error al realizar el ingreso del vehículo.",
                icon: "error",
                confirmButtonClass: "btn btn-outline-danger",
                confirmButtonText: "Ok",
                buttonsStyling: false
              });
              console.error(error);
            });
      }
    }
  },
  watch: {
    'vehicle.brand': async function(id) {
      this.getModels(id)
    },
    'vehicle.displacement': async function(value){
      this.vehicle.displacement = parseFloat(value)
    },
    'vehicle.mileage': async function(value){
      this.vehicle.mileage = parseInt(value,10)
    },
    'vehicle.owners': async function(value){
      this.vehicle.owners = parseInt(value,10)
    },
    'vehicle.year': async function(value){
      this.vehicle.year = parseInt(value,10)
    }
  }
}
</script>